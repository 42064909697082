exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-founders-tsx": () => import("./../../../src/pages/founders.tsx" /* webpackChunkName: "component---src-pages-founders-tsx" */),
  "component---src-pages-gameometry-tsx": () => import("./../../../src/pages/gameometry.tsx" /* webpackChunkName: "component---src-pages-gameometry-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-past-events-tsx": () => import("./../../../src/pages/past-events.tsx" /* webpackChunkName: "component---src-pages-past-events-tsx" */),
  "component---src-pages-sound-containment-tsx": () => import("./../../../src/pages/sound-containment.tsx" /* webpackChunkName: "component---src-pages-sound-containment-tsx" */)
}

