module.exports = [{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-b532be7f64/0/cache/gatsby-plugin-canonical-urls-npm-5.12.0-9f594da5c8-9bfc0005dd.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.windupensemble.com"},
    },{
      plugin: require('../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-61170c4a41/0/cache/gatsby-plugin-manifest-npm-5.9.0-c1dd563b36-541ad24d54.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2ecca8a0bb04107dd2ee53a6e9e049c0"},
    },{
      plugin: require('../../.yarn/unplugged/gatsby-virtual-acfb614085/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
